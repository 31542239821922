import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import heroImage from './media/mainHeroImage.png';
import Countdown from 'react-countdown';
import countdownOne from './media/countdownOne.png';
import countdownTwo from './media/countdownTwo.png';
import countdownThree from './media/countdownThree.png';
import countdownFour from './media/countdownFour.png';
import venue from './media/venue.png';
import accommodation from './media/accommodation.png';
import { FaWhatsapp } from 'react-icons/fa';
import BankingDetails from './media/BankingDetails.pdf';
import AccommodationOptions from './media/AccommodationOptions.pdf';

function App() {
	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const info = params.get('info');

	// Countdown

	const [visibleSections, setVisibleSections] = useState({}); // Track visibility of sections
	const countdownRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setVisibleSections((prev) => ({
							...prev,
							[entry.target.id]: true,
						}));
					}
				});
			},
			{ threshold: 0.1 } // Trigger when 10% of the element is in the viewport
		);

		const countdownItems =
			countdownRef.current.querySelectorAll('.countdown-item');
		countdownItems.forEach((item) => observer.observe(item));

		return () => observer.disconnect(); // Cleanup
	}, []);

	const renderer = ({ days, hours, minutes, seconds }) => {
		return (
			<section className="countdown-section" ref={countdownRef}>
				<div
					id="days-section"
					className={`countdown-item ${
						visibleSections['days-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownOne} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{days}</p>
						<p className="countdown-uom">days</p>
					</div>
				</div>
				<div
					id="hours-section"
					className={`countdown-item ${
						visibleSections['hours-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownTwo} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{hours}</p>
						<p className="countdown-uom">hours</p>
					</div>
				</div>
				<div
					id="minutes-section"
					className={`countdown-item ${
						visibleSections['minutes-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownThree} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{minutes}</p>
						<p className="countdown-uom">minutes</p>
					</div>
				</div>
				<div
					id="seconds-section"
					className={`countdown-item ${
						visibleSections['seconds-section'] ? 'visible' : ''
					}`}
				>
					<img src={countdownFour} alt="" className="countdown-image" />
					<div className="countdown-unit">
						<p className="countdown-number">{seconds}</p>
						<p className="countdown-uom">seconds</p>
					</div>
				</div>
			</section>
		);
	};

	const images = [
		require('./media/gallery/Gallery (1).png'),
		require('./media/gallery/Gallery (2).png'),
		require('./media/gallery/Gallery (3).png'),
		require('./media/gallery/Gallery (4).png'),
		require('./media/gallery/Gallery (5).png'),
		require('./media/gallery/Gallery (6).png'),
		require('./media/gallery/Gallery (7).png'),
		require('./media/gallery/Gallery (8).png'),
		require('./media/gallery/Gallery (9).png'),
		require('./media/gallery/Gallery (10).png'),
		require('./media/gallery/Gallery (11).png'),
		require('./media/gallery/Gallery (12).png'),
		require('./media/gallery/Gallery (13).png'),
		require('./media/gallery/Gallery (14).png'),
		require('./media/gallery/Gallery (15).png'),
		require('./media/gallery/Gallery (16).png'),
		require('./media/gallery/Gallery (17).png'),
		require('./media/gallery/Gallery (18).png'),
		require('./media/gallery/Gallery (19).png'),
		require('./media/gallery/Gallery (20).png'),
		require('./media/gallery/Gallery (21).png'),
		require('./media/gallery/Gallery (22).png'),
		require('./media/gallery/Gallery (23).png'),
		require('./media/gallery/Gallery (24).png'),
		require('./media/gallery/Gallery (25).png'),
		require('./media/gallery/Gallery (26).png'),
		require('./media/gallery/Gallery (27).png'),
		require('./media/gallery/Gallery (28).png'),
		require('./media/gallery/Gallery (29).png'),
		require('./media/gallery/Gallery (30).png'),
	];

	// Logic for FAQ section

	const [activeIndex, setActiveIndex] = useState(null);

	const toggleQuestion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const faqData = [
		{
			question: 'What should I wear?',
			answer:
				'The dress code is formal. Guests are encouraged to add a pop of color with pastels, florals, or patterns to complement our Secret Garden theme.',
		},
		{
			question: 'Can I bring a plus-one?',
			answer:
				'Please refer to your invitation for specifics. If your plus-one is included, their name will be listed on the invite.',
		},
		{
			question: 'Are Kids Allowed?',
			answer:
				'We love your little ones, but only a select few children will be joining us for the day. If your child is invited, their name will be included on the invitation.',
		},
		{
			question: 'Where will the ceremony and reception take place?',
			answer:
				'Both the ceremony and reception will be held at Au d’Hex Estate.',
		},
		{
			question: 'Is there parking available at the venue?',
			answer:
				'Yes, parking will be available onsite. Please follow the signs for guest parking upon arrival.',
		},
		{
			question: 'Will there be a cash bar?',
			answer:
				'No need to worry about cash! The bar will be fully stocked and open.',
		},
		{
			question: 'What time should I arrive?',
			answer:
				'The ceremony will begin promptly at 2.pm. Please arrive 15-20 minutes earlier to find your seat.',
		},
		{
			question: 'Will the ceremony be indoors or outdoors?',
			answer:
				'The ceremony will be held outdoors in the garden, weather permitting. Please dress accordingly.',
		},
		{
			question: 'Can I take photos during the ceremony?',
			answer:
				'Yes, you’re welcome to take photos during the ceremony! We do kindly ask that you remain seated and mindful of our photographers and videographers so they can capture the best moments.',
		},
		{
			question: 'What if I have dietary restrictions?',
			answer:
				'We’ve planned a variety of menu options to accommodate different dietary needs. If you have specific restrictions, please let us know as soon as possible.',
		},
		{
			question: 'Can I post on social media?',
			answer:
				'Yes! Feel free to share the love on social media. Use our hashtag, #foreverdefreitas, so we can see your lovely photos.',
		},
		{
			question: 'Is there a specific gift registry?',
			answer:
				'Your presence is our gift! However, if you’d like to bless us with a gift, we would appreciate a contribution towards our honeymoon fund. It would mean the world to us. Banking details can be found on our wedding website. ',
		},
		{
			question: 'Will there be transport to and from the venue?',
			answer:
				'Yes! For guests staying in nearby Airbnbs or hotels, a shuttle service will be available to and from the venue. More details will be shared closer to the wedding day.',
		},
		{
			question: 'Who should I contact for additional questions?',
			answer:
				'If you have any further questions, feel free to reach out to Simone or Pavo on WhatsApp. We’re happy to help!',
		},
	];

	return (
		<div className="App">
			<section className="heroSection">
				<img src={heroImage} className="hero-image" alt=""></img>
				<div className="hero-section-content">
					<h3>Simone</h3>
					<div className="inline">
						<div className="line"></div>
						<h4>and</h4>
						<div className="line"></div>
					</div>
					<h3>Pavo</h3>
				</div>
			</section>
			<Countdown date={new Date('2025-04-05T14:00:00')} renderer={renderer} />
			<section className="invite-section">
				<p>You are cordially invited to the wedding of</p>
				<br />
				<p className="name">Simone Solomon</p>
				<p>and</p>
				<p className="name">Pavo De Freitas</p>
				<br />
				<p>Saturday | April 05 | 2025</p>
				<p>Au d'Hex | Wellington</p>
			</section>
			<section className="rsvp-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Kindly</span>
						<br /> RSVP
					</h1>
					<div className="line"></div>
				</div>
				<p>
					We can't wait to celebrate our special day with you! To help us plan
					the festivities, we kindly ask you to RSVP by the{' '}
					<strong> 19th of January</strong>
				</p>
				<p>
					Let us know if you'll be joining us, and don't forget to include any
					dietary requirements or special requests - we want everyone to have an
					amazing time!
				</p>
				<br />
				<br />
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLSfbl9ce5OY2k1ZkWmDI5DPkthfrRnyFBdDzV6jixnGqyMkN_A/viewform?usp=dialog"
					className="button-link"
				>
					RSVP Here
				</a>
			</section>
			<section className="ourStory-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Our</span>
						<br /> Story
					</h1>
					<div className="line"></div>
				</div>
				<p>
					At fifteen and seventeen, we first said hello, through a friend named
					Jesse, years ago. We crossed paths briefly, here and there, but
					destiny had plans to share. On Simone's eighteenth, with friends we'd
					meet, a casual smoke, a birthday treat. We swapped BBM pins, texted
					till dawn, and just like that, a bond was born. From matric ball dates
					to years that flew, twelve years later, Pavo knew: This was the love
					he'd always keep, So he proposed, and Simone took the leap. Now here
					we stand, ready to say, “Forever starts with our wedding day!”
				</p>
				<hr />
			</section>
			<section className="venue-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">About&nbsp;the</span>
						<br /> Venue
					</h1>
					<div className="line"></div>
				</div>
				<img src={venue} alt="" className="venue-image" />
				<p>
					Nestled in the scenic heart of Wellington, Au d'Hex is a venue where
					elegance meets the beauty of nature. This enchanting estate offers a
					blend of historic charm and modern luxury, making it an unforgettable
					setting for our wedding celebration.
				</p>
				<br />
				<p>
					With its lush vineyards, picturesque gardens, and majestic mountain
					views, Au d'Hex provides a serene and sophisticated atmosphere. From
					the grand architecture of its halls to the cozy outdoor spaces, every
					corner of the estate has been thoughtfully designed to create a
					magical experience.
				</p>
				<br />
				<p>
					Join us in celebrating our love surrounded by the timeless allure of
					this exquisite venue.
				</p>
				<hr />
			</section>
			<section className="accommodation-section">
				<img src={accommodation} alt="" className="accommodation-image" />
				{/* Conditional Info for Bridal Party */}
				{info === 'bridalparty' && (
					<div>
						<p>
							For our bridal party, we've reserved a special stay at the
							beautiful Au d'hex Guesthouse. Located right on the estate, this
							charming guesthouse blends comfort and luxury, offering an ideal
							retreat for our closest family and friends.
						</p>
						<p>
							The guesthouse can accommodate up to 20 guests, ensuring a cozy
							and intimate atmosphere for those dearest to us. Each room is
							elegantly furnished, with stunning views of the vineyards and
							surrounding mountains, creating a relaxing and picturesque
							experience.
						</p>
						<p>Please also join us on for dinner on Friday at</p>
						<p>
							<strong>5PM for 5:30PM</strong>
						</p>
						<p>
							We're thrilled to share this wonderful space with you, and we hope
							it provides the perfect beginning to our celebrations together!
						</p>
						<hr />
					</div>
				)}
				{/* Conditional Info for non-bridal party */}
				{info == null && (
					<div>
						<p>
							We have curated a list of accommodation options to make your stay
							as comfortable and convenient as possible while celebrating with
							us. Each option is within easy reach of our wedding venue and we
							will also have a shuttle service available for transport between
							the venue.
						</p>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.0552167358264!2d19.011680575541693!3d-33.62981027331889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcd0f4cae555bb9%3A0x7ae49ea24e39b3d3!2sAu%20d&#39;%20Hex%20Estate!5e0!3m2!1sen!2sza!4v1734079351073!5m2!1sen!2sza"
							width="90%"
							height="300"
							title="wedding-map"
						></iframe>
						<br />
						<br />
						<br />
						<a
							href={AccommodationOptions}
							rel="noreferrer"
							target="_blank"
							className="button-link"
						>
							See Options
						</a>
					</div>
				)}
			</section>
			<section className="details-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Wedding</span>
						<br /> Details
					</h1>
					<div className="line"></div>
				</div>
				<h3>
					1.30 PM <span className="smallCaps">for</span> 2 PM
				</h3>
				<p className="event-item">Guest Arrival</p>
				<p className="event-place">Au d'Hex, Wellington</p>
				<hr />
				<h3>
					2 PM <span className="smallCaps">to</span> 2.30 PM
				</h3>
				<p className="event-item">Ceremony</p>
				<p className="event-place">The Deck, Au d'Hex</p>
				<hr />
				<h3>2.45 PM</h3>
				<p className="event-item">Pre-drinks</p>
				<p className="event-place">Rose Garden</p>
				<hr />
				<h3>5.15 PM</h3>
				<p className="event-item">Reception</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>5.30 PM</h3>
				<p className="event-item">Starters</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>6 PM</h3>
				<p className="event-item">Speeches</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>7.15 PM</h3>
				<p className="event-item">Grooms Speech + Cake-Cutting</p>
				<p className="event-place">Reception Hall</p>
				<hr />
				<h3>7.30 PM</h3>
				<p className="event-item">First Dance + Party Begins</p>
				<p className="event-place">Dance Floor</p>
				<hr />
				<h3>
					<span className="cursive">Party ends at</span> Midnight
				</h3>
				<div className="heading">
					<div className="line"></div>
					<h5>Dress&nbsp;Code</h5>
					<div className="line"></div>
				</div>
				<p>
					Our wedding theme is <em>secret garden</em>
				</p>
				<br />
				<p>
					We'd love for our guests to join in the magic by dressing in vibrant
					colors such as these:
				</p>
				<div className="color-dots">
					<div className="color-dot one"></div>
					<div className="color-dot two"></div>
					<div className="color-dot three"></div>
					<div className="color-dot four"></div>
				</div>
				<p>
					We encourage our guests to have fun with our theme and wear playful
					patterns!
				</p>
			</section>
			<section className="gifts-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Wedding</span>
						<br /> Gifts
					</h1>
					<div className="line"></div>
				</div>
				<p>
					Your presence at our wedding is the greatest gift we could ever ask
					for, and we truly mean it!
				</p>
				<p>
					However, if you would like to contribute something extra special,
					we've set up a honeymoon fund to help us create unforgettable memories
					as we embark on this exciting new chapter together.
				</p>
				<p>
					Your generosity will go towards making our dream trip a reality, and
					we'll think of you with gratitude every step of the way.
				</p>
				<p>
					Thank you for your love, support, and for being part of our journey!
				</p>
				<br />
				<br />
				<a
					href={BankingDetails}
					target="_blank"
					rel="noreferrer"
					className="button-link"
				>
					Donate
				</a>
			</section>
			<section className="faq-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Your</span>
						<br /> FAQ<span className="lowercase">s</span>
					</h1>
					<div className="line"></div>
				</div>
				{faqData.map((item, index) => (
					<div key={index} className="faq-item">
						<div
							className="question-content"
							onClick={() => toggleQuestion(index)}
						>
							<p>{item.question}</p>
							<p>{activeIndex === index ? '-' : '+'}</p>
						</div>
						{activeIndex === index && <p className="answer">{item.answer}</p>}
					</div>
				))}
			</section>
			<section className="contact-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Contact</span>
						<br /> Us
					</h1>
					<div className="line"></div>
				</div>
				<p>
					If you have any questions or need assistance with RSVPs, travel
					arrangements, or anything else, please feel free to reach out to us!
				</p>
				<div className="contact-links">
					<a href="https://wa.me/971582854404" className="whatsapp-link">
						<FaWhatsapp className="icon" /> Simone
					</a>
					<a href="https://wa.me/27814138078" className="whatsapp-link">
						<FaWhatsapp className="icon" /> Pavo
					</a>
				</div>
			</section>
			<section className="gallery-section">
				<div className="heading">
					<div className="line"></div>
					<h1>
						<span className="cursive">Photo</span>
						<br /> Gallery
					</h1>
					<div className="line"></div>
				</div>
				<div className="gallery">
					{images.map((src, index) => (
						<img key={index} src={src} alt={`Gallery ${index + 1}`} />
					))}
				</div>
			</section>
			<footer>
				<p>
					2024 &copy; <a href="https://wed-ify.co.za">Wedify.co.za</a>
				</p>
			</footer>
		</div>
	);
}

export default App;
